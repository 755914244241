:root {
    /*  --color-first: #242220; */
    --color-first: #575757;
    --color-second: #46A7FA;
    --color-third: #242220;
    --color-fourth: #3c9df0;
    --color-fifth: #676767;
    --color-bg: #575757;
    --color-text: #868686;
    --color-text-white: #fff;
    --z-normal: 1;
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

body {
    background-color: var(--color-bg) !important;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-text);
    padding: 0px;
    margin: 0px;
    font-family: Arial, Verdana;
}

body::-webkit-scrollbar {
    width: 6px;
    /*=> ancho del scroll*/
    background-color: #cecece;
    /*=> Color del fondo principal */
}

body::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.3);
    /*=> color de fondo del trayecto del scroll */
}

body::-webkit-scrollbar-thumb {
    background-color: var(--color-second);
    /*=> color del indicador del scroll */
    border-radius: 10px;
    /*=> bordes redondeados */
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none !important;
}

.btn-naharasoft {
    color: white !important;
    background-color: var(--color-second) !important;
    border-color: var(--color-second) !important;
}

.btn-naharasoft:hover {
    background-color: var(--color-fourth) !important;
    border-color: var(--color-fourth) !important;
}

.btn-naharasoft-banner {
  color: white !important;
  background-color:  var(--color-fifth)!important;
  border-color: var(--color-fifth) !important;
}

.btn-naharasoft-banner:hover {
  background-color: var(--color-first) !important;
  border-color: var(--color-first) !important;
}

.text-banner{
  text-align: center;
  font-size: 1.7rem;
}


/* Paragraph of texts */
.paragraph-gray {
  color: var(--color-first);
}

.paragraph-white {
  color: var(--color-text-white);
}
/*
Colors of Banners
*/

.main-wrapper-banner-blue{
  background-color: var(--color-second);
}

/*
Colors of Item Service
*/

.color-gray {
    background: #D1D2D4;
}

.color-lightblue {
    background: #03A9F4;
}

.color-yellow {
    background: #F1C30F;
}

.color-green {
    background: var(--color-second);
}

.color-teal {
    background: #80CBC4;
}

.color-kode-warna {
    background: var(--color-second);
}

.color-cyan {
    background: #00BCD4;
}

.color-limegreen {
    background: #71D456;
}

.color-title-gray {
    color: var(--color-first) !important;
}

.color-title-gray:after {
    border-bottom: 2px solid var(--color-first) !important;
}

.color-title-gray:hover {
    color: var(--color-second) !important;
}

.color-title-gray:hover:after {
    border-bottom: 2px solid var(--color-second) !important;
}


/* Styles for Main Header */
.navbar-icon-color{
  color: var(--color-first) !important;
}

.main-header {
    border-bottom: 1px solid #ccc;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, .95);
    opacity: .95;
}

.navbar-nav{
    color: var(--color-first) !important;
}

.item-menuHeader>a {    
    font-weight: 600;
    position: relative;
    display: block;
    padding: 5px 15px 5px 15px;
}

.item-menuHeader>a:hover {
    color: var(--color-second) !important;
}

.nav-item a {
    position: relative;
    display: block;
    padding: 5px 15px 5px 15px;
}

.nav-item a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, var(--color-second), var(--color-second), var(--color-second));
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
}

.nav-item a:hover::before {
    transform: scaleX(1);
}


/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.nav-item[data-animation="to-left"] a::before {
    transform-origin: right;
}

.nav-item[data-animation="center"] a::before {
    transform-origin: center;
}

.nav-item[data-animation="bonus"] a::before {
    transform-origin: right;
}

.nav-item[data-animation="bonus"] a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
}

.main-logo {
    height: 3rem;
    width: 14rem;
}

.div-toggle {
    display: none !important;
}

.toggle-menu {
    font-size: 1.8rem;
    color: var(--color-first);
}

.toggle-menu:hover {
    color: var(--color-second);
}


/* Styles for NAV of Main Header */

.main-menu-link {
    color: var(--color-first);
    font-size: .9rem;
}

.main-menu-link:hover {
    color: var(--color-second);
}

.main-active-link, .main-active-link:active {
    color: var(--color-second) !important;
    border-bottom: 3px solid var(--color-second);
}


/* Styles generals for Wrapper Section of page */

.main-wrapper {
    padding: 30px 0px 20px 0px;
}

.main-wrapper-services,
.main-wrapper-contact {
    background-color: var(--color-text-white);
}

.main-wrapper-banner {
    height: 240px;
    position: relative;
    overflow: hidden;
}

.main-wrapper-banner>img {
    position: absolute;
    top: -200px;
    left: 0;
    width: 100%;
    height: calc(100% + 400px);
    object-fit: cover;
}

.main-wrapper-banner:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 9;
}

.wrapperRootPage {
    min-height: 500px;
}

.wrapper-imgfullWidth {
    height: 400px;
    max-height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    width: 100% !important;
}

.wrapperTitleFulllWidth {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentTitleFullWidth {
    margin: 0 20px;
}

.title-imgFullWidth {
    margin: 0px;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
}

.subTitle-imgFullWidth {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    display: block;
}

.scrollParallax {
    height: auto;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wrapper-title-h1 {
    width: 100%;
}

.wrapper-content {
    padding: 40px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
}

.title-h1 {
    text-align: center;
    color: var(--color-text-white);
    font-size: 1.8rem;
}

.title-h1:after {
    content: "";
    margin: 10px auto 0px auto;
    width: 150px;
    display: block;
    border-bottom: 2px solid var(--color-text-white);
}

.title-h1:hover {
    color: var(--color-second);
}

.title-h1:hover:after {
    border-bottom: 2px solid var(--color-second);
}

.wrapper-title-h3 {
    min-height: 3rem;
    width: 100%;
}

.title-h3 {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: var(--color-first);
}

.title-h3:hover {
    color: var(--color-second);
}

.title-h4 {
    text-align: left;
    color: var(--color-second);
}

.title-h4:hover {
    color: var(--color-first);
}

.wrapper-text {
    padding: 0rem 0.5rem 0.5rem 0rem;
    text-align: justify;
    color: var(--color-third);
}


/* Styles for Section Services */

.wrapper-list-services {
    width: 100%;
}

.wrapper-item-services,
.wrapper-item-technologies {
    width: 100%;
    background: #e5e5e5;
    transition: transform .2s;
    border-style: none;
    border-radius: 5px;
    margin: 1rem 0px 1rem 0.1rem;
    box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 0.10);
}

.wrapper-item-technologies {
    opacity: 0.5;
}

.wrapper-item-services:hover,
.wrapper-item-technologies:hover {
    color: white;
    background: var(--color-text-white);
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.wrapper-item-technologies:hover {
    opacity: 1;
}

.content-item-services,
.content-item-technologies {
    width: 100%;
    padding: 1rem 0rem 1rem 0rem;
    margin: 0 auto;
    cursor: pointer;
}

.image-item-services,
.image-item-technologies {
    width: 120px;
    height: 120px;
    margin: 0 auto 1rem auto;
    text-align: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-item-payment-gateway>img {
    width: 100% !important;
    margin: 0 auto;
}

.image-item-services>img,
.image-item-technologies>img {
    width: 65%;
    margin: 0 auto;
}

.image-item-technologies>img {
    width: 55%;
}

.description-item-services {
    width: 95%;
    margin: 0 auto;
    text-align: justify;
    color: var(--color-first);
}

.description-item-services>p {
    font-size: 0.8rem;
}


/* Styles for the Section of Technologies*/

.list-technologies {}

.list-technologies>li {
    display: inline-block;
    width: 10%;
    margin: 0 auto;
    text-align: center;
}

.logo_technologies {
    width: 70%;
    opacity: 0.5;
    margin: 0 auto;
}

.logo_technologies:hover {
    opacity: 1;
}

.logo_resize_javascript,
.logo_resize_php,
.logo_resize_html {
    width: 53%;
}

.logo_resize_laravel {
    width: 80%;
}

.logo_resize_backbonejs {
    width: 90%;
}

.logo_resize_reactjs {
    width: 60%;
}

.logo_resize_java {
    width: 38%;
}

.imageCenter {
    display: block !important;
    margin: 0 auto !important;
    text-align: center !important;
    width: 100%;
}

.main-footer {
    width: 100%;
    height: auto;
}

.form-group {
    margin: 0 auto 1rem auto;
}

.form-group>label {
    display: inline-block;
    margin-bottom: .5rem;
}

.formInput {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.formTextarea {
    display: block;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    width: 100%;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.formButton {
    background: var(--color-first);
    color: white;
    display: inline-block;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem 1rem;
    font-size: 1rem;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.formButton:hover {
    background: var(--color-second);
    color: var(--color-text-white);
}


/* Styles generals */

.paragraph {
    text-align: justify;
    padding: 0px 20px;
    color: var(--color-text-white);
}

.paragraph-middle {
    font-size: 1.2rem;
}

.title-banner {
    position: absolute;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    padding: 0px 20px;
    width: 100%;
    z-index: 99;
    color: var(--color-text-white);
}

.parallax-container {
    height: auto;
    width: 100%;
    max-width: 100%;
}


/* Begin BackToTop button css */

#buttonWhatsApp {
  position: fixed;
  right: 25px;
  bottom: 180px;
  cursor: pointer;
  width: 50px !important;
  height: 50px;
  display: block;
  z-index: 99;
}

#iconButtonWhatsApp{
  width: 50px !important;
  height: 50px;
}

#iconButtonSmall{
  width: 35px !important;
  height: 35px;
}

#scroll {
    position: fixed;
    right: 25px;
    bottom: 100px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: var(--color-second);
    text-indent: -9999px;
    display: block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    z-index: 99;
}

#scroll span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -12px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-bottom-color: #ffffff
}

#scroll:hover {
    background-color: var(--color-fourth);
    border: 1px solid var(--color-fourth);
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
}


/* End BackToTop button css */


/* Begin Buttons */

.wrapper-button-default {
    padding: 40px 0px;
    display: block;
    width: 30%;
}

.button-default {
    background: rgba(126, 186, 1, 0.7);
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 0px solid rgba(126, 186, 1, 0.7);
    color: rgba(255, 255, 255, 0.7);
    display: block;
    font-weight: 700;
    margin: 0.5rem auto 0.5rem auto;
    padding: 15px 15px;
    text-align: center;
    text-decoration: none;
    max-width: 30%;
}

.button-default:hover {
    background: var(--color-second);
    border: 0px solid var(--color-second);
    color: white;
    text-decoration: none;
}


/* Begin Buttons */


/* Styles with Media Query for the resolution of 1024PX */

@media screen and (max-width: 990px) {
    .navbar-nav {
        margin-top:25px;
    }
    .item-menuHeader>a{
        padding: 10px;
    }
    .wrapper-imgfullWidth {
        height: auto;
        max-height: auto;
    }
  }
  

@media screen and (max-width: 620px) {
  .nav-item {
      margin-top:10px;
      padding: 5px 10px;
  }

  .paragraph{
      margin-top: 20px;
  }
}

@media screen and (min-width: 640px) {
    .main-menu {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media screen and (max-width: 640px) {
    .main-logo {
        height: 2.8rem;
        width: 11rem;
    }
    .main-menu {
        display: none;
        position: fixed;
        top: 4rem;
        right: 0;
        width: 50%;
        background: var(--color-bg);
        z-index: var(--z-fixed);
    }
    .div-toggle {
        display: flex !important;
        position: fixed !important;
        right: 0 !important;
    }
    #slides,
    .wrapper-imgfullWidth {
        margin-top: 4rem !important;
    }
}

@media screen and (max-width:600px) {
    #slides,
    .wrapper-imgfullWidth {
        margin-top: 4rem !important;
    }
    .divSlider-title,
    .divSlider-description {
        margin-top: 3rem !important;
    }
    .button-default {
        max-width: 40%;
    }
}


@media screen and (max-width:575px) {
    .wrapper-item-technologies {
        margin: 1rem auto 1rem auto !important;
    }
}


/* Begin Loader css */
.overlay-spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: var(--color-second);
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--color-second), 0 0 5px var(--color-second);
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -moz-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    -o-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: var(--color-second);
    border-left-color: var(--color-second);
    border-radius: 10px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes pace-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes pace-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes pace-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* End Loader css */
